import $ from 'jquery'
import './main.scss'

function showModal(sectionElem) {
  let $target
  event.preventDefault()
  if (($target = $(sectionElem))) {
    let $parent
    if (
      !$target.data('simpleroPopUpFormSectionId') &&
      ($parent = $target.parents('a')).data('simpleroPopUpFormSectionId')
    ) {
      $target = $parent
    }

    if ($target && $target.data('simpleroPopUpFormSectionId')) {
      const sectionId = $target
        .data('simpleroPopUpFormSectionId')
        .split('//')[1]
      const $modal = $(`#simplero-pop-up-form-modal-${sectionId}`)
      $modal.addClass('simplero-modal-enter')

      $modal.removeClass('simplero-modal-exit')

      $modal.css('display', 'block')
      $modal.find('input:visible').first().focus()

      return $(document).on('click', '.simplero-modal', function (e) {
        const closeButtonEl = this.querySelector('#section-modal-close-btn')

        if (
          e.target === $modal[0] ||
          e.target === closeButtonEl ||
          closeButtonEl.contains(e.target)
        ) {
          $modal.removeClass('simplero-modal-enter')
          $modal.addClass('simplero-modal-exit')

          setTimeout(() => {
            $modal.css('display', 'none')
          }, 350)
        }
      })
    }
  }
}

$(document).on('click', '[data-simplero-pop-up-form-section-id]', (e) =>
  showModal(e.target)
)
